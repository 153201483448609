<template>
  <v-row no-gutters>
    <v-col cols="12" xl="9" order="1" order-xl="13">
      <v-row>
        <v-col
          cols="12"
          v-for="(option, i) in local_options"
          :key="`${i + 100}`"
        >
          <v-row class="bordered px-4">
            <v-col cols="6" class="pl-0">
              <v-checkbox
                class="ma-0"
                color="primary"
                hide-details
                label="This is a required option"
                v-model="option.required"
              />
            </v-col>
            <v-col cols="6" class="text-right pr-0">
              <v-btn
                color="accent"
                class="mr-0"
                elevation="0"
                rounded
                text
                @click="local_options.splice(i, 1)"
                >Delete option</v-btn
              >
            </v-col>
            <v-row v-for="(option_conf, j) in option.options" :key="`${j}`">
              <v-col
                cols="12"
                md="4"
                class="pb-0"
                :class="{
                  hidden: j !== 0,
                  'not-shown': j !== 0 && $vuetify.breakpoint.mdAndDown,
                }"
              >
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Instruction <span class="red--text">*</span>
                </p>
                <v-text-field
                  class="pt-0 mt-0 mb-0"
                  single-line
                  dense
                  validate-on-blur
                  v-model.lazy="local_options[i].name"
                  required
                  :rules="option_name_name"
                />
              </v-col>
              <v-col cols="12" md="4" class="pb-0">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  {{
                    j === 0
                      ? (option.required ? `Base` : `Default`) + ` Option`
                      : `#${j} Option`
                  }}
                  <span class="red--text">*</span>
                </p>
                <v-text-field
                  class="pt-0 mt-0 mb-0"
                  single-line
                  dense
                  v-model="option_conf.name"
                  required
                  :rules="option_name_name"
                  @keyup.enter="
                    option.options.push({ name: null, price: null })
                  "
                />
              </v-col>
              <v-col cols="12" md="3" class="pb-0">
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Price (£) (+/-) <span class="red--text">*</span>
                </p>
                <v-text-field
                  class="pt-0 mt-0 mb-0"
                  single-line
                  dense
                  type="number"
                  :disabled="j === 0"
                  v-model.number="option_conf.price"
                  :rules="rules_price"
                  @keyup.enter="
                    option.options.push({ name: null, price: null })
                  "
                />
              </v-col>
              <v-col cols="1" class="d-flex align-center pb-0" v-if="j !== 0">
                <v-btn icon color="accent" @click="option.options.splice(j, 1)"
                  ><v-icon small color="accent" v-text="'$delete'"
                /></v-btn>
              </v-col>
              <v-col
                cols="12"
                class="text-right"
                v-if="j + 1 === option.options.length"
              >
                <v-btn
                  icon
                  color="green"
                  @click="option.options.push({ name: null, price: null })"
                  ><v-icon large>mdi-plus</v-icon></v-btn
                >
              </v-col>
            </v-row>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" xl="3" order="13" order-xl="1" class="text-right">
      <v-btn
        rounded
        class="alt-button mt-2 mr-0"
        outlined
        @click="
          local_options.push({
            name: null,
            required: false,
            options: [{ name: null, price: 0 }],
          });
          new_option_name = null;
        "
        >+ Add Extra Options</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import {
  field_req,
  is_number,
  count_2_decimals,
  txt_min_2_char,
} from "@/utils/form_val_rules";

export default {
  name: "ProductOptions",
  data() {
    return {
      local_options: [],
      new_option_name: null,
      new_name_valid: false,
      rules_name: [txt_min_2_char],
      option_name_name: [field_req, txt_min_2_char],
      rules_price: [field_req, is_number, count_2_decimals],
    };
  },
  props: {
    options: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.local_options = this.options;
  },
  methods: {
    handle_new_option_enter() {
      this.$refs["add-option-btn"].click();
    },
  },
  watch: {
    local_options: {
      deep: true,
      handler() {
        this.$emit("update_options", this.local_options);
      },
    },
    options: {
      deep: true,
      handler: function (options) {
        this.local_options = options;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.bordered {
  border-radius: 8px;
  border: 1px solid var(--v-primary-base);
}
.alt-button {
  background-color: #dff4f5 !important;
  border: none;
  text-transform: none;

  &::v-deep .v-btn__content {
    font-size: 1.15em;
    color: var(--v-primary-base);
  }
}
</style>
