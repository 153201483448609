<template>
  <v-container fluid tag="section">
    <v-container fluid tag="section" class="py-0">
      <v-row class="secondary px-4 br-8 pt-0 pb-2">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="search_term"
            append-icon="mdi-magnify"
            label="Search by Product Name"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-combobox
            :items="categories_name_list"
            v-model="category_filter"
            label="Filter by Category"
            hide-details
            clearable
          ></v-combobox
        ></v-col>
        <v-col cols="12" md="4" class="justify-end d-flex align-end">
          <v-btn
            color="yellow"
            class="mr-0"
            rounded
            @click="show_product_form = true"
          >
            + Add new product
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-row class="px-4 py-4 justify-space-between" no-gutters>
      <v-btn
        :disabled="!selected_products.length"
        @click="display_bulk_update_dialog = true"
        rounded
        color="primary"
        >Bulk Edit</v-btn
      >
    </v-row>
    <v-data-table
      show-select
      v-model="selected_products"
      :headers="headers"
      :items="products"
      :search="search_term"
      :items-per-page="10"
      :item-class="table_row_class"
      class="br-8"
      item-key="id"
    >
      <template v-slot:header.enabled="{ header }">
        <v-tooltip top content-class="custom-tooltip">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on" class="mr-2">mdi-information-outline</v-icon>
            <span>{{ header.text }}</span>
          </template>
          <span>
            Only active products show on the shop front. Those with zero
            quantity will show as "Sold Out".
          </span>
        </v-tooltip>
      </template>

      <template v-slot:item.name="{ item }">
        <span>
          <i class="font-weight-bold">
            {{
              item.is_shared && item._shared_by
                ? `(${item._shared_by.name}) `
                : ""
            }}
          </i>
          {{ item.name }}
        </span>
      </template>

      <template v-slot:item.category="{ item }">
        <span>
          {{ all_product_categories[Object.keys(item.categories)[0]] }}
        </span>
      </template>

      <template v-slot:item.image="{ item }">
        <div
          :key="item.image"
          class="product-image br-8"
          :style="{
            'background-image':
              item.image !== null ? `url(${item.image})` : false,
          }"
        ></div>
      </template>

      <template v-slot:item.enabled="{ item }">
        <div class="d-flex justify-center">
          <v-switch
            color="primary"
            v-model="item.enabled"
            @change="toggle_enabled(item)"
          ></v-switch>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <div
          class="d-flex flex-row justify-space-between"
          v-if="!item._shared_by"
        >
          <v-tooltip bottom content-class="custom-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="d-flex align-center justify-center cursor-pointer mr-4"
                :class="{
                  'mr-8': $vuetify.breakpoint.smAndDown,
                }"
                @click="handle_edit(item)"
              >
                <v-icon small color="dark-grey" v-text="'$edit'" />
              </div>
            </template>
            Edit
          </v-tooltip>
          <v-tooltip bottom content-class="custom-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="d-flex align-center justify-center cursor-pointer mr-4"
                :class="{
                  'mr-8': $vuetify.breakpoint.smAndDown,
                }"
                @click="handle_duplicate_product(item)"
              >
                <v-icon small color="dark-grey" v-text="'$copy'" />
              </div>
            </template>
            Duplicate
          </v-tooltip>
          <v-tooltip bottom content-class="custom-tooltip">
            <template v-slot:activator="{ on }">
              <div
                v-on="on"
                class="d-flex align-center justify-center cursor-pointer mr-4"
                @click="handle_delete(item)"
              >
                <v-icon small color="accent" v-text="'$delete'" />
              </div>
            </template>
            Delete
          </v-tooltip>
        </div>
      </template>

      <template v-slot:no-data>
        <p>Sorry no products, try to add some</p>
      </template>
    </v-data-table>

    <BulkUpdateProducts
      :disabled="!display_bulk_update_dialog"
      :products="selected_products"
      @close="display_bulk_update_dialog = false"
      @reset-selected-products="selected_products = []"
    />
    <ProductForm
      :visible="show_product_form"
      :duplicate_product="duplicate_product"
      :selected_product="selected_product"
      @duplicate_complete="selected_product = null"
      @close="
        show_product_form = false;
        selected_product = null;
        duplicate_product = false;
      "
    />
  </v-container>
</template>

<script>
import Swal from "sweetalert2";
import { mapState, mapGetters } from "vuex";
import { firestore } from "firebase/app";

import { db } from "@/db";
import ProductForm from "../forms/ProductForm";
import BulkUpdateProducts from "@/views/dashboard/forms/BulkUpdateProducts";

export default {
  name: "Products",
  components: {
    ProductForm,
    BulkUpdateProducts,
  },
  data() {
    return {
      search_term: "",
      category_filter: null,
      show_product_form: false,
      selected_product: null,
      selected_products: [],
      duplicate_product: false,
      display_bulk_update_dialog: false,

      headers: [
        {
          text: "Image",
          value: "image",
          sortable: false,
          filterable: false,
          align: "center",
        },
        {
          text: "Name",
          align: "start",
          value: "name",
          filterable: true,
        },
        { text: "SKU", value: "sku", filterable: false },
        {
          text: "Category",
          value: "category",
          filterable: true,
          filter: (category) => {
            if (!this.category_filter) {
              return true;
            }
            return category === this.category_filter;
          },
        },
        { text: "Price (£)", value: "price", filterable: false },
        { text: "Qty", value: "quantity", filterable: false },
        {
          text: "Status",
          value: "enabled",
          filterable: false,
          align: "center",
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          align: "center",
          filterable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState("AdminStore", [
      "shop",
      "shop_id",
      "product_categories",
      "shop_products_categories",
    ]),
    ...mapGetters("AdminStore", ["products", "all_product_categories"]),
    categories_name_list: function () {
      if (!this.shop_products_categories || !this.all_product_categories)
        return [];
      return Object.keys(this.shop_products_categories)
        .map((id) => this.all_product_categories[id])
        .sort();
    },
  },
  methods: {
    table_row_class(item) {
      const classes = [];
      if (!item.enabled) classes.push("not-enabled");
      if (item.is_shared && item._shared_by) classes.push("--shared");
      return classes.join(" ");
    },
    async delete_product(product) {
      const ref = this.get_product_document_reference(product);
      try {
        await db.runTransaction(async (t) => {
          const doc = await t.get(ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.delete(ref);
        });
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
    async update_product_field(product, field, value) {
      const ref = this.get_product_document_reference(product);
      try {
        await db.runTransaction(async (t) => {
          const doc = await t.get(ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.update(ref, {
            [field]: value,
            updated_at: firestore.FieldValue.serverTimestamp(),
          });
        });
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
    toggle_enabled(product) {
      this.update_product_field(product, "enabled", product.enabled);
    },
    get_product_document_reference(product) {
      return db
        .collection("shops")
        .doc(this.shop_id)
        .collection("products")
        .doc(product.id);
    },
    handle_duplicate_product(product) {
      this.duplicate_product = true;
      this.selected_product = product;
      this.show_product_form = true;
    },
    handle_edit(product) {
      this.selected_product = product;
      this.show_product_form = true;
    },
    async handle_delete(product) {
      const { value: accept } = await Swal.fire({
        title: "Delete",
        text: `Are you sure you want to delete ${product.name}?`,
        type: "warning",
        confirmButtonText: "Delete",
        confirmButtonColor: "#54c4c9",
        showCancelButton: true,
      });

      if (accept) {
        this.delete_product(product);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.product-image {
  height: 50px;
  width: 70px;
  background-size: cover;
  background-position: center center;
  margin: 0 auto;
}
.md-card .md-card-actions {
  border: 0;
  margin-left: 20px;
  margin-right: 20px;
}
::v-deep .not-enabled {
  color: #c9c9c9;
}
::v-deep .--shared {
  background-color: #84faff38;
}
::v-deep .not-enabled .product-image {
  opacity: 0.3;
}
</style>
