import firebase from "firebase/app";
import "firebase/storage";

export function upload_file(file_path, file, name = null) {
  const storageRef = firebase
    .storage()
    .ref(`${file_path}/${Date.now()}_${name || file.name}`);

  const uploadTask = storageRef.put(file);
  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => reject(error),
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        resolve(downloadURL);
      }
    );
  });
}

export async function upload_string(dataURL, file_path) {
  const storageRef = firebase.storage().ref(`${file_path}/${Date.now()}`);

  const uploadTask = storageRef.put(dataURL);
  return new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
      },
      (error) => reject(error),
      async () => {
        const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
        resolve(downloadURL);
      }
    );
  });
}
