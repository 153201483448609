<template>
  <v-dialog v-model="show" persistent max-width="500px">
    <v-card class="br-16">
      <v-card-title
        class="primary font-weight-bold pb-4 text-center text-h3 white--text"
        >Update {{ products.length }} products</v-card-title
      >
      <v-card-text v-if="!is_updating_shared_products" class="px-10">
        <p class="mb-0 headline input-label font-weight-bold dark-grey--text">
          Product Status
        </p>
        <v-checkbox v-model="update_status" class="d-inline-block mr-4">
          <template #label>
            <span class="font-weight-bold">Update product status</span>
          </template>
        </v-checkbox>
        <v-switch
          v-if="update_status"
          v-model="enabled"
          class="d-inline-block mt-0"
        >
          <template #label>
            <span class="font-weight-bold">{{
              enabled ? "Enable all products" : "Disable all products"
            }}</span>
          </template>
        </v-switch>
        <p class="mb-0 headline input-label font-weight-bold dark-grey--text">
          Category
        </p>
        <v-autocomplete
          class="pt-0 mt-0 mb-0"
          ref="$category_select"
          :items="categories_name_list"
          v-model="product_category"
          no-data-text="Cannot find a category"
          clearable
        />
        <p class="mb-0 headline input-label font-weight-bold dark-grey--text">
          Allergens guidance (contains)
        </p>
        <v-select
          class="pt-0 mt-0 mb-0"
          :items="PRODUCT_ALLERGENS"
          v-model="allergens"
          :multiple="true"
          clearable
        />
        <p
          class="
            mb-0
            mr-2
            headline
            input-label
            font-weight-bold
            dark-grey--text
          "
          style="top: 0px"
        >
          Available Qty
        </p>
        <v-text-field
          class="pt-0 mt-0"
          v-model="quantity"
          type="number"
          step="1"
          min="0"
          single-line
          flat
          :rules="rules_qty"
        />
        <p class="mb-0 headline input-label font-weight-bold dark-grey--text">
          Price (£)
        </p>
        <v-text-field
          class="pt-0 mt-0 mb-0"
          v-model="price"
          autocomplete="off"
          type="number"
          step="0.01"
          min="0.01"
          :rules="rules_price"
        />
        <p class="mb-0 headline input-label font-weight-bold dark-grey--text">
          VAT (%)
        </p>
        <v-select
          class="pt-0 mt-0"
          :items="list_vats"
          v-model="vat"
          clearable
          @click:clear.stop.prevent="vat = null"
        />
        <p class="mb-0 headline input-label font-weight-bold dark-grey--text">
          Qty Limit (per order)
        </p>
        <v-text-field
          class="pt-0"
          v-model="quantity_order_limit"
          type="number"
          step="1"
          min="1"
          single-line
          :rules="rules_qty_order_limit"
        />
      </v-card-text>
      <v-card-text v-else>
        <p>
          Sorry, you selected products which are shared with you by other
          business. You cannot update them, only owner can.
        </p>
      </v-card-text>
      <v-card-actions class="justify-end pb-4 px-6">
        <v-btn color="primary" rounded text @click="clear()">Close</v-btn>
        <v-btn
          :disabled="loading || Object.keys(formatted_updates).length === 0"
          color="primary"
          rounded
          @click="update_products()"
          class="mr-0"
        >
          <span v-if="!loading">Update</span>
          <v-progress-circular
            v-else
            size="10"
            indeterminate
            color="white"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { Decimal } from "decimal.js";
import { mapState, mapGetters } from "vuex";

import { PRODUCT_ALLERGENS } from "@/views/dashboard/dashboard_data";
import { format_string_to_uid } from "@/utils/firestore";
import { update_products } from "@/requests";
import {
  txt_min_2_char,
  is_number,
  val_min_0,
  val_min_001,
  val_min_1,
  count_0_decimals,
  count_2_decimals,
} from "@/utils/form_val_rules";

export default {
  name: "BulkUpdateProducts",
  props: {
    disabled: {
      type: Boolean,
      default: true,
    },
    products: {
      type: Array,
    },
  },
  data() {
    return {
      PRODUCT_ALLERGENS,
      loading: false,
      product_category: null,
      update_status: false,
      enabled: false,
      allergens: [],
      quantity: null,
      price: null,
      vat: null,
      quantity_order_limit: null,
      list_vats: [0, 5, 10, 20],
      rules_name: [txt_min_2_char],
      rules_qty: [is_number, val_min_0, count_0_decimals],
      rules_qty_order_limit: [is_number, val_min_1, count_0_decimals],
      rules_price: [is_number, val_min_001, count_2_decimals],
      updates: {
        category: null,
        categories: {},
      },
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop", "shop_id"]),
    ...mapGetters("AdminStore", ["all_product_categories"]),
    is_updating_shared_products() {
      return this.products.some((p) => p._shared_by);
    },
    formatted_updates() {
      let updates = { ...this.updates };

      if (this.allergens.length) updates.allergens = this.allergens;
      if (this.product_category) {
        const cat_id = format_string_to_uid(this.product_category);
        updates.category = this.product_category;
        updates.categories = {
          [cat_id]: true,
        };
      } else {
        delete updates.category;
        delete updates.categories;
      }
      if (this.quantity)
        updates.quantity = Number(Decimal(this.quantity).toFixed(2));
      if (this.price) updates.price = Number(Decimal(this.price).toFixed(2));
      if (![undefined, null].includes(this.vat)) updates.vat = this.vat;
      if (this.quantity_order_limit)
        updates.quantity_order_limit = Number(
          Decimal(this.quantity_order_limit).toFixed(2)
        );
      if (this.update_status) updates.enabled = this.enabled;

      return updates;
    },
    categories_name_list: function () {
      if (this.all_product_categories) {
        return Object.values(this.all_product_categories).sort();
      }
      return [];
    },
    show: {
      get() {
        return !this.disabled;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    async update_products() {
      this.loading = true;
      const products_ids = this.products.map((o) => o.id);

      try {
        await update_products(
          this.shop_id,
          products_ids,
          this.formatted_updates
        );
        this.clear();
        this.$emit("close");
        this.$emit("reset-selected-products");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
    clear() {
      this.show = false;
      this.product_category = null;
      this.allergens = [];
      this.quantity = null;
      this.price = null;
      this.vat = null;
      this.quantity_order_limit = null;
      this.update_status = false;
      this.enabled = false;
      this.updates = {
        category: null,
        categories: {},
      };
    },
  },
};
</script>
