<template>
  <v-dialog
    v-model="show"
    scrollable
    persistent
    :fullscreen="$vuetify.breakpoint.mdAndDown"
    max-width="70vw"
  >
    <v-card
      class="action-modal"
      :class="{ 'br-16': $vuetify.breakpoint.mdAndUp }"
    >
      <v-card-title
        class="primary white--text font-weight-bold py-3 align-center"
      >
        <span v-if="selected_product">Edit {{ selected_product.name }}</span>
        <span v-else>Add New Product</span>
        <v-btn icon class="close-icon mr-4" @click="(show = false), clear()"
          ><v-icon color="white" large v-text="'$close'"
        /></v-btn>
      </v-card-title>
      <v-card-text class="pt-0">
        <v-container
          fluid
          class="action-modal-content"
          :class="{ 'px-6': $vuetify.breakpoint.mdAndUp }"
        >
          <v-form v-model="form_valid" ref="form">
            <v-row>
              <v-col
                cols="12"
                md="9"
                :class="{ 'px-2': $vuetify.breakpoint.mdAndUp }"
              >
                <v-row class="br-16 light-grey mb-2 px-4">
                  <v-col cols="12" md="8">
                    <p
                      class="
                        mb-0
                        headline
                        input-label
                        font-weight-bold
                        dark-grey--text
                      "
                    >
                      Name <span class="red--text">*</span>
                    </p>
                    <v-text-field
                      class="pt-0 mt-0 mb-0"
                      v-model="product.name"
                      :rules="rules_name"
                      single-line
                      flat
                      maxlength="50"
                      counter="50"
                      required
                    />
                    <p class="headline mb-1 font-weight-bold dark-grey--text">
                      Description
                    </p>
                    <v-textarea
                      style="border-radius: 8px"
                      rounded
                      outlined
                      single-line
                      flat
                      v-model="product.description"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                    :class="{ 'px-6': $vuetify.breakpoint.mdAndUp }"
                  >
                    <p
                      class="
                        mb-0
                        headline
                        input-label
                        font-weight-bold
                        dark-grey--text
                      "
                    >
                      Category <span class="red--text">*</span>
                    </p>
                    <v-autocomplete
                      class="pt-0 mt-0 mb-0"
                      ref="$category_select"
                      :items="categories_name_list"
                      v-model="product_category"
                      :rules="rules_category"
                      no-data-text="Cannot find a category"
                      required
                    />
                    <p
                      class="
                        mb-0
                        headline
                        input-label
                        font-weight-bold
                        dark-grey--text
                      "
                    >
                      Allergen guidance (contains)
                    </p>
                    <v-select
                      class="pt-0 mt-0 mb-0"
                      :items="list_allergens"
                      v-model="product.allergens"
                      :multiple="true"
                    />
                    <p
                      class="
                        mb-0
                        mr-2
                        headline
                        input-label
                        font-weight-bold
                        dark-grey--text
                        d-inline-block
                      "
                      style="top: 0px"
                    >
                      Available Qty <span class="red--text">*</span>
                    </p>
                    <v-text-field
                      class="pt-0 mt-0 mb-2 d-inline-block width-50"
                      v-model="product.quantity"
                      type="number"
                      step="1"
                      min="0"
                      single-line
                      hide-details
                      flat
                      :rules="rules_qty"
                      required
                    />
                  </v-col>
                </v-row>
                <ProductOptions
                  :options="product.options"
                  @update_options="(options) => (product.options = options)"
                />
              </v-col>
              <v-col
                cols="12"
                md="3"
                :class="{ 'pl-8 pt-6': $vuetify.breakpoint.mdAndUp }"
              >
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Supplier
                </p>
                <v-text-field
                  class="pt-0 mt-0 mb-0"
                  v-model="product.supplier"
                  autocomplete="off"
                  type="text"
                />
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  Price (£) <span class="red--text">*</span>
                </p>
                <v-text-field
                  class="pt-0 mt-0 mb-0 width-50"
                  v-model="product.price"
                  autocomplete="off"
                  type="number"
                  step="0.01"
                  min="0.01"
                  :rules="rules_price"
                  required
                />
                <p
                  class="
                    mb-0
                    headline
                    input-label
                    font-weight-bold
                    dark-grey--text
                  "
                >
                  VAT (%) <span class="red--text">*</span>
                </p>
                <v-select
                  class="pt-0 mt-0 mb-0 width-50"
                  :items="list_vats"
                  v-model="product.vat"
                  :rules="rules_vat"
                  required
                  hide-details
                />
                <div>
                  <v-checkbox
                    v-if="
                      shop.shares_products_with &&
                      Object.keys(shop.shares_products_with).length
                    "
                    v-model="product.is_shared"
                    class="ml-0 d-inline-block"
                    label="Share Product with partners"
                    hide-details
                  />
                  <v-checkbox
                    v-model="qty_limit_per_order"
                    class="ml-0 d-inline-block"
                    label="Qty Limit (per order)"
                    hide-details
                  />
                  <div v-if="show_qty_limit_per_order">
                    <v-text-field
                      class="pt-0 mt-0 mb-0 ml-1 width-50"
                      v-model="product.quantity_order_limit"
                      type="number"
                      step="1"
                      min="1"
                      hide-details
                      required
                      single-line
                      :rules="rules_qty_order_limit"
                    />
                  </div>
                </div>
                <div class="mb-4">
                  <v-checkbox
                    v-model="use_sku"
                    class="ml-0 d-inline-block"
                    label="SKU"
                    hide-details
                  />
                  <div v-if="show_sku">
                    <v-text-field
                      class="pt-0 mt-0 ml-1 width-50"
                      v-model="product.sku"
                      hide-details
                      required
                      single-line
                    />
                  </div>
                </div>
                <div class="d-flex align-center">
                  <p
                    class="
                      mb-0
                      mr-2
                      headline
                      font-weight-bold
                      dark-grey--text
                      d-inline-block
                    "
                  >
                    Image
                  </p>
                  <label class="primary--text" for="resized-product-image"
                    >Add Image</label
                  >
                </div>
                <image-uploader
                  id="resized-product-image"
                  :debug="0"
                  :maxWidth="512"
                  :quality="0.7"
                  :autoRotate="true"
                  outputFormat="blob"
                  :preview="false"
                  :capture="false"
                  accept="image/*"
                  doNotResize="['gif', 'svg']"
                  @input="(file) => (product_image = file)"
                  @onUpload="loading_image = true"
                  @onComplete="loading_image = false"
                />
                <div
                  v-if="product.image"
                  id="product-image-output"
                  class="product-image"
                >
                  <v-progress-circular
                    v-if="loading_image"
                    :size="20"
                    indeterminate
                    color="primary"
                  />
                  <v-img class="br-16" :src="product.image" />
                  <v-btn
                    small
                    icon
                    class="--button"
                    @click="product.image = null"
                    ><v-icon small color="accent" v-text="'$delete'"
                  /></v-btn>
                </div>
              </v-col>
              <v-row>
                <v-col cols="3"> </v-col>
              </v-row>
            </v-row>
          </v-form>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-end pb-4">
        <v-btn
          style="height: 35px"
          color="primary"
          rounded
          large
          @click="submit()"
          :disabled="!form_valid || loading"
        >
          <v-progress-circular
            v-if="loading"
            :size="20"
            indeterminate
            color="white"
          />
          <span v-else class="font-weight-bold"
            >{{ selected_product ? "Save" : "Add new" }} product</span
          >
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { Decimal } from "decimal.js";
import ImageUploader from "vue-image-upload-resize";

import { mapState, mapGetters } from "vuex";
import { db, firestore } from "@/db";

import { upload_file } from "@/utils/storage";
import { format_string_to_uid } from "@/utils/firestore";
import { PRODUCT_ALLERGENS } from "@/views/dashboard/dashboard_data";
import {
  field_req,
  txt_min_2_char,
  is_number,
  val_min_0,
  val_min_001,
  val_min_1,
  count_0_decimals,
  count_2_decimals,
} from "@/utils/form_val_rules";

import ProductOptions from "./ProductOptions";

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    selected_product: {
      type: Object,
      default: null,
    },
    duplicate_product: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ImageUploader,
    ProductOptions,
  },
  data() {
    return {
      loading_image: false,
      loading: false,
      form_valid: false,
      product_image: null,
      selected_product_id: null,
      show_qty_limit_per_order: false,
      show_sku: false,
      product: {
        name: null,
        sku: null,
        category: null,
        description: null,
        allergens: null,
        price: null,
        vat: null,
        quantity: null,
        quantity_order_limit: null,
        image: null,
        enabled: true,
      },
      list_allergens: PRODUCT_ALLERGENS,
      list_vats: [0, 5, 10, 20],
      rules_name: [field_req, txt_min_2_char],
      rules_category: [field_req],
      rules_qty: [field_req, is_number, val_min_0, count_0_decimals],
      rules_qty_order_limit: [is_number, val_min_1, count_0_decimals],
      rules_price: [field_req, is_number, val_min_001, count_2_decimals],
      rules_vat: [
        field_req,
        (v) => this.list_vats.includes(v) || "Please select VAT",
      ],
    };
  },
  computed: {
    ...mapState("AdminStore", ["shop_id", "shop"]),
    ...mapGetters("AdminStore", ["all_product_categories"]),
    categories_name_list: function () {
      if (this.all_product_categories) {
        return Object.values(this.all_product_categories).sort();
      }
      return [];
    },
    product_category: {
      get: function () {
        let category = null;

        if (this.product.categories) {
          let category_id, is_active;

          for ([category_id, is_active] of Object.entries(
            this.product.categories
          )) {
            if (is_active) {
              category = this.all_product_categories[category_id];
            }
          }
        }
        return category;
      },
      set: function (category) {
        if (!category) return;

        // NB: decisions done for having more flexibility when implementing
        // Products settings pagination:
        //  - keep "category" on the product (denormalised pattern)

        const cat_id = format_string_to_uid(category);
        this.product["categories"] = {
          [cat_id]: true,
        };
        this.product["category"] = category;
      },
    },
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
    qty_limit_per_order: {
      get() {
        return this.show_qty_limit_per_order;
      },
      set(v) {
        this.show_qty_limit_per_order = v;
        if (v === false) this.product.quantity_order_limit = null;
      },
    },
    use_sku: {
      get() {
        return this.show_sku;
      },
      set(v) {
        this.show_sku = v;
        if (v === false) this.product.sku = null;
      },
    },
  },
  watch: {
    selected_product(product) {
      if (product) {
        if (this.duplicate_product) {
          this.product = cloneDeep(product);
          this.product.name = null;
          this.$emit("duplicate_complete");
        } else {
          this.selected_product_id = product.id;
          this.product = cloneDeep(product);
        }
        this.qty_limit_per_order = !["", null].includes(
          this.product.quantity_order_limit
        );
        this.use_sku = !["", null].includes(this.product.sku);
      }
    },
    product_image(image) {
      let url = this.product.image || null;
      if (image) {
        url = window.URL.createObjectURL(image);
      }
      // document.getElementById(
      //   "product-image-output"
      // ).style.backgroundImage = `url(${url})`;
      this.product.image = url;
    },
  },
  methods: {
    submit: async function () {
      this.loading = true;
      if (this.selected_product_id) {
        await this.update_product();
        this.show = false;
      } else {
        await this.add_product();
      }
      this.loading = false;
      this.clear();
    },
    async add_product() {
      const format_case = "create";

      try {
        if (this.product_image) {
          let file_name = format_string_to_uid(this.product.name);
          const pic_url = await upload_file(
            `shops/${this.shop_id}/products/images`,
            this.product_image,
            file_name
          );
          this.product.image = pic_url;
        }
        await db
          .collection("shops")
          .doc(this.shop_id)
          .collection("products")
          .add(this.format_product(this.product, format_case));
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
    async update_product() {
      const format_case = "update";
      const ref = db
        .collection("shops")
        .doc(this.shop_id)
        .collection("products")
        .doc(this.selected_product_id);

      try {
        if (this.product_image) {
          let file_name = format_string_to_uid(this.product.name);
          const pic_url = await upload_file(
            `shops/${this.shop_id}/products/images`,
            this.product_image,
            file_name
          );
          this.product.image = pic_url;
        }
        await db.runTransaction(async (t) => {
          const doc = await t.get(ref);
          if (!doc.exists) {
            throw "Document does not exist";
          }
          t.update(ref, this.format_product(this.product, format_case));
        });
      } catch (err) {
        console.error("Transaction failed", err);
      }
    },
    clear() {
      this.selected_product_id = null;
      this.product_image = null;
      this.use_sku = false;
      this.qty_limit_per_order = false;
      this.product_category = null;
      this.product = {
        name: null,
        sku: null,
        category: null,
        description: null,
        allergens: null,
        price: null,
        vat: null,
        quantity: null,
        quantity_order_limit: null,
        image: null,
        enabled: true,
      };
      this.$refs.$category_select.reset();
      this.reset_vals();
    },
    format_product(product, format_case) {
      const new_product = cloneDeep(product);
      new_product.price = Number(Decimal(new_product.price).toFixed(2));
      new_product.quantity = Number(Decimal(new_product.quantity).toFixed(0));
      if (new_product.quantity_order_limit) {
        new_product.quantity_order_limit = Number(
          Decimal(new_product.quantity_order_limit).toFixed(0)
        );
      } else {
        new_product.quantity_order_limit = null;
      }
      if (format_case === "create") {
        new_product.created_at = firestore.FieldValue.serverTimestamp();
      }
      new_product.updated_at = firestore.FieldValue.serverTimestamp();
      return new_product;
    },
    reset_vals() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style lang="scss" scoped>
.product-image {
  position: relative;
  max-height: 500px;
  // min-height: 300px;
  max-width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .--button {
    opacity: 1;

    @media #{map-get($display-breakpoints, 'md-and-up')} {
      opacity: 0;
    }

    background-color: var(--v-light-grey-base);
    position: absolute;
    top: -10px;
    right: -14px;
  }

  &:hover {
    .--button {
      opacity: 1;
    }
  }
}
.input-label {
  position: relative;
  bottom: -5px;
}
.width-50 {
  width: 50%;
}
.close-icon {
  position: absolute;
  right: 0;
}
</style>
