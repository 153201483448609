var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","tag":"section"}},[_c('v-container',{staticClass:"py-0",attrs:{"fluid":"","tag":"section"}},[_c('v-row',{staticClass:"secondary px-4 br-8 pt-0 pb-2"},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search by Product Name","single-line":"","hide-details":""},model:{value:(_vm.search_term),callback:function ($$v) {_vm.search_term=$$v},expression:"search_term"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-combobox',{attrs:{"items":_vm.categories_name_list,"label":"Filter by Category","hide-details":"","clearable":""},model:{value:(_vm.category_filter),callback:function ($$v) {_vm.category_filter=$$v},expression:"category_filter"}})],1),_c('v-col',{staticClass:"justify-end d-flex align-end",attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"mr-0",attrs:{"color":"yellow","rounded":""},on:{"click":function($event){_vm.show_product_form = true}}},[_vm._v(" + Add new product ")])],1)],1)],1),_c('v-row',{staticClass:"px-4 py-4 justify-space-between",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"disabled":!_vm.selected_products.length,"rounded":"","color":"primary"},on:{"click":function($event){_vm.display_bulk_update_dialog = true}}},[_vm._v("Bulk Edit")])],1),_c('v-data-table',{staticClass:"br-8",attrs:{"show-select":"","headers":_vm.headers,"items":_vm.products,"search":_vm.search_term,"items-per-page":10,"item-class":_vm.table_row_class,"item-key":"id"},scopedSlots:_vm._u([{key:"header.enabled",fn:function(ref){
var header = ref.header;
return [_c('v-tooltip',{attrs:{"top":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2"},on),[_vm._v("mdi-information-outline")]),_c('span',[_vm._v(_vm._s(header.text))])]}}],null,true)},[_c('span',[_vm._v(" Only active products show on the shop front. Those with zero quantity will show as \"Sold Out\". ")])])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',[_c('i',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(item.is_shared && item._shared_by ? ("(" + (item._shared_by.name) + ") ") : "")+" ")]),_vm._v(" "+_vm._s(item.name)+" ")])]}},{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm.all_product_categories[Object.keys(item.categories)[0]])+" ")])]}},{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('div',{key:item.image,staticClass:"product-image br-8",style:({
          'background-image':
            item.image !== null ? ("url(" + (item.image) + ")") : false,
        })})]}},{key:"item.enabled",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-center"},[_c('v-switch',{attrs:{"color":"primary"},on:{"change":function($event){return _vm.toggle_enabled(item)}},model:{value:(item.enabled),callback:function ($$v) {_vm.$set(item, "enabled", $$v)},expression:"item.enabled"}})],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [(!item._shared_by)?_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center cursor-pointer mr-4",class:{
                'mr-8': _vm.$vuetify.breakpoint.smAndDown,
              },on:{"click":function($event){return _vm.handle_edit(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"dark-grey"},domProps:{"textContent":_vm._s('$edit')}})],1)]}}],null,true)},[_vm._v(" Edit ")]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center cursor-pointer mr-4",class:{
                'mr-8': _vm.$vuetify.breakpoint.smAndDown,
              },on:{"click":function($event){return _vm.handle_duplicate_product(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"dark-grey"},domProps:{"textContent":_vm._s('$copy')}})],1)]}}],null,true)},[_vm._v(" Duplicate ")]),_c('v-tooltip',{attrs:{"bottom":"","content-class":"custom-tooltip"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [_c('div',_vm._g({staticClass:"d-flex align-center justify-center cursor-pointer mr-4",on:{"click":function($event){return _vm.handle_delete(item)}}},on),[_c('v-icon',{attrs:{"small":"","color":"accent"},domProps:{"textContent":_vm._s('$delete')}})],1)]}}],null,true)},[_vm._v(" Delete ")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('p',[_vm._v("Sorry no products, try to add some")])]},proxy:true}]),model:{value:(_vm.selected_products),callback:function ($$v) {_vm.selected_products=$$v},expression:"selected_products"}}),_c('BulkUpdateProducts',{attrs:{"disabled":!_vm.display_bulk_update_dialog,"products":_vm.selected_products},on:{"close":function($event){_vm.display_bulk_update_dialog = false},"reset-selected-products":function($event){_vm.selected_products = []}}}),_c('ProductForm',{attrs:{"visible":_vm.show_product_form,"duplicate_product":_vm.duplicate_product,"selected_product":_vm.selected_product},on:{"duplicate_complete":function($event){_vm.selected_product = null},"close":function($event){_vm.show_product_form = false;
      _vm.selected_product = null;
      _vm.duplicate_product = false;}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }